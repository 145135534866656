import React, { useContext, useEffect } from "react"
import { Route, Routes } from "react-router-dom"

import { getUserCommunities, getUserDetails } from "./services/api/user"
import { useLogger } from "./providers/LoggerProvider"
import { UserContext } from "./providers/UserContext"

import PageHeader from "./layout/PageHeader"
import UserService from "./UserService"
import { DashboardsPage, HomePage, Logout, MyFilesPage, ProfilePage, TranslateFilePage, TranslateTextPage } from "./sites"
import { UserRole } from "src/models/common"
import DashboardsInternalPage from "./sites/dashboards-internal"

export const BiPortalApp: React.FC = () => {

  const { user, setUser, setUserCommunities } = useContext(UserContext)
  const { logger } = useLogger()

  useEffect(() => {
    initUser()
  }, [])

  const initUser = async () => {
    try {
      setUser(await getUserDetails())
      setUserCommunities(await getUserCommunities())
    } catch (e) {
      console.error(e)
      logger.error('System - Failed to init user', e)
    }
  }

  return (
    <>
      <PageHeader />
      {!!user && (
        <Routes>
          {!!user && (<>
            <Route path='' element={<HomePage />} />
            <Route path='text' element={<TranslateTextPage />} />
            <Route path='document' element={<TranslateFilePage />} />
            <Route path='files' element={<MyFilesPage />} />
            <Route path='profile' element={<ProfilePage />} />
            {UserService.isAdminOrPM() && (
              <Route path="bi" element={<DashboardsPage/>} />
            )}
            {UserService.isAdminOrPM() && (
              <Route path="bi-internal" element={<DashboardsInternalPage/>} />
            )}
          </>)}
          <Route path="logout" element={<Logout />} />  
        </Routes>
      )}
    </>
  )
}