/* eslint-disable operator-linebreak */
import Keycloak from "keycloak-js"

import { Role, UserRole } from "src/models/common"

const _kc = Keycloak({
  url: process.env.REACT_APP_AUTHORIZATION_SERVER_URL ? process.env.REACT_APP_AUTHORIZATION_SERVER_URL : window.location.origin + "/auth",
  clientId: "exfluency-bi-portal-frontend",
  realm: "exfluency-realm"
})

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc.init({
    onLoad: 'check-sso',
    pkceMethod: 'S256'
  })
    .then((authenticated) => {
      if (!authenticated && !unauthenticatedPath()) {
        doLogin()
      } else {
        onAuthenticatedCallback()
      }
    })
    .catch(console.error)
}

const unauthenticatedPath = () => ['/logout', '/terms-of-service', '/privacy-notice'].some(e => e === window.location.pathname) 
  || window.location.pathname.includes('/verify-email') 
  || window.location.pathname.includes('/forgot-password') 
  || window.location.pathname.includes('/signup') 
  || window.location.pathname.includes('/auth')

const doLogin = _kc.login

const doLogout = _kc.logout

const isLoggedIn = () => !!_kc.token

const getToken = async () => {
  await updateToken(() => (console))
  return _kc.token
}

const updateToken = (successCallback: () => void) =>
  _kc.updateToken(10)
    .then(successCallback)
    .catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username

const isExfluencyUser = () => _kc.tokenParsed?.email.endsWith("@exfluency.com")

const hasRoles = (checkRoles: Role[], exact = false): boolean => {
  const roles = (_kc.tokenParsed?.resource_access?.['exfluency-workflow']?.roles || []) as Role[]

  if(exact) {
    return roles.every(role => checkRoles.includes(role))
  }
  return roles.some(role => checkRoles.includes(role))
}

const isAdminOrPM = () => hasRoles([UserRole.EXFLUENCY_ADMIN, UserRole.PLATFORM_MANAGER])

const getRoles = () => (_kc.tokenParsed?.resource_access?.['exfluency-workflow']?.roles || []) as Role[]

const isAuthenticated = () => _kc.authenticated &&
  _kc.token ? Promise.resolve() : Promise.reject("Failed to obtain access token.")

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRoles,
  unauthenticatedPath,
  getRoles,
  isAuthenticated,
  isExfluencyUser,
  isAdminOrPM: isAdminOrPM
}

export default UserService
