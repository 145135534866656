/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { Button } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"

import PageContainer from "src/layout/PageContainer"

import './style.css'
import UserService from "src/UserService"
import { AssessmentOutlined, DashboardOutlined, LockOutlined, HistoryEduOutlined, InsertDriveFileOutlined } from "@mui/icons-material"
import { UserRole } from "src/models/common"

const HomePage: React.FC = () => {

  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const isRequester = UserService.hasRoles([UserRole.BUYER])
  const isAdmin = UserService.isAdminOrPM()
  const isExfluencyUser = UserService.isExfluencyUser()
  const isAidVariant = process.env.REACT_APP_VARIANT === 'aid'
  
  const openDahsboards = () => {
    window.open(process.env.REACT_APP_DASHBOARDS_URL, '_blank')
  }

  return (
    <PageContainer title={isAidVariant ? t('home.title') : ''} split={isRequester || isAdmin}>
      {isRequester ?
        <>
          <div className="page-content home-content">
            <InsertDriveFileOutlined className="content-icon"/>
            <h2>{t('home.document.title')}</h2>
            <p>{t('home.document.description')}</p>
            <Button variant="contained" onClick={() => navigate('/document')}>{t('home.document.action')}</Button>
          </div>
        </> :
        <></>
      }

      <div className={`page-content home-content ${(isRequester) ? '' : 'home-content--full-width'} `}>
        <HistoryEduOutlined className="content-icon"/>
        <h2>{t('translate.title')}</h2>
        <p>{isAidVariant ? t('home.text.description') : 'Secure search in own data with assistance from 6+ NMT engines. Edit, copy, and save anonymized result'}</p>
        <Button variant="contained" onClick={() => navigate('/text')}>{isAidVariant ? t('translate.title') : 'Search & Create'}</Button>
      </div>

      {isAdmin ? (
        <div className="page-content home-content">
          <AssessmentOutlined className="content-icon"/>
          <h2>BI Overview</h2>
          <p>Exfluency Factor, Recycling and Human Parity percentages, engine usage and editing distances</p>
          <Button variant="contained" onClick={() => navigate('/bi')}>Move to BI Overview</Button>
        </div> 
      ) : <></>}

      {isAdmin && isExfluencyUser ? (
        <div className="page-content home-content">
          <LockOutlined className="content-icon"/>
          <h2>BI (Internal) Overview</h2>
          <p>Internal BI dashboards visible only to Exfluency employees</p>
          <Button variant="contained" onClick={() => navigate('/bi-internal')}>Move to internal BI Overview</Button>
        </div>
      ) : <></>}

      {isAdmin ? (
        <div className="page-content home-content">
          <DashboardOutlined className="content-icon"/>
          <h2>Admin dashboard</h2>
          <p>Monitor projectes, communities, and related activites in ecosystem</p>
          <Button variant="contained" onClick={openDahsboards}>Open dashboards app</Button>
        </div>
      ) : <></>}

    </PageContainer>
  )
}

export default HomePage